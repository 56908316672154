import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { UIUX } from "../ui-ux";
import { Design } from "../design";
import { Code } from "../code";
import "./application.scss";

export class Application extends React.Component {
  render() {
    return (
      <Router>
        <div className="application-wrapper">
          <Switch>
            <Route path="/design" component={Design} />
            <Route path="/code" component={Code} />
            <Route exact path="/" component={UIUX} />
          </Switch>
        </div>
      </Router>
    );
  }
}
