import React from "react";
import "./design.scss";
import { Header } from "../../components/header/header.jsx";
import { DesignExamples } from "../../components/design-examples";
import { UIUXExamples } from "../../components/ui-ux-examples";

export class Design extends React.Component {
  render() {
    return (
      <div className="design-wrapper">
        <Header description="UI + UX + Branding" />
        <DesignExamples />
        <UIUXExamples />
      </div>
    );
  }
}
