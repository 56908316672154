import React from "react";
import "./ui-ux-examples.scss";

export class UIUXExample extends React.Component {
  render() {
    const { idx, appendClass = "", hideOnMobile = false } = this.props;
    return (
      <div
        className={`ui-ux-example-wrapper ui-ux-element-${idx} ${appendClass} ${
          hideOnMobile ? "hide-on-mobile" : ""
        }`}
      >
        <img
          src={`/assets/images/examples/ui-ux/${idx}.png`}
          alt={`UI + UX Example ${idx}`}
          className="ui-ux-example"
        />
      </div>
    );
  }
}
