import React from "react";
import "./design-examples.scss";

export class DesignExample extends React.Component {
  render() {
    const { idx, appendClass = "", hideOnMobile = false } = this.props;
    return (
      <div
        className={`design-example-wrapper design-element-${idx} ${appendClass} ${
          hideOnMobile ? "hide-on-mobile" : ""
        }`}
      >
        <img
          src={`/assets/images/examples/design/${idx}.png`}
          alt={`Design Example ${idx}`}
          className="design-example"
        />
      </div>
    );
  }
}
