import React from "react";
import "./header.scss";

export class Header extends React.Component {
  render() {
    const { description } = this.props;

    return (
      <div className="header-wrapper">
        <div className="header-photo-wrapper">
          <img
            src="/assets/images/main.png"
            alt="Justin"
            className="header-photo"
          />
        </div>
        <div className="header-info-wrapper">
          <div className="header-name">Justin Lawrence</div>
          <div className="header-description">{description}</div>
        </div>
        <a
          href="mailto:justinmarklawrence55@gmail.com"
          className="header-contact"
          title="Email Justin"
        >
          justinmarklawrence55@gmail.com
        </a>
      </div>
    );
  }
}
