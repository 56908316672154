import React from "react";
import "./code.scss";
import { Header } from "../../components/header/header.jsx";
import { CodeExamples } from "../../components/code-examples";

export class Code extends React.Component {
  render() {
    return (
      <div className="design-wrapper">
        <Header description="Code" />
        <CodeExamples />
      </div>
    );
  }
}
