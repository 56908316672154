import React from "react";
import "./design-examples.scss";
import { DesignExample } from "./design-example.jsx";
import { parallaxTiltEffect } from "../ui-ux-examples/hover-effect.js";

export class DesignExamples extends React.Component {
  componentDidMount() {
    const $ = (e) => document.querySelector(e);
    for (var i = 1; i <= 1; i++) {
      console.log(`.design-element-${i}`);
      new parallaxTiltEffect({
        element: $(`.design-element-${i}`),
        tiltEffect: "reverse",
        isDesign: true,
      });
    }
  }
  render() {
    return (
      <div className="design-examples-wrapper">
        <DesignExample idx="1" />
      </div>
    );
  }
}
