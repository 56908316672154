import React from "react";
import "./code-examples.scss";

export default class CodeExample extends React.Component {
    render() {
      const { example } = this.props;
    
    return (
     <div className="code-example-wrapper">
         <a href={example.link} className="code-example-heading" title={`See ${example.name} in action`}>{example.name}</a>
         <img src={`assets/images/examples/code/${example.imageURL}`} alt={`${example.name}`} className="code-example-image"/>
         <div className="code-example-about-wrapper">
            <div className="code-example-about-heading">About</div>
            <div className="code-example-about-text">{example.description}</div>
         </div>
         <div className="code-example-technologies-wrapper">
            <div className="code-example-technologies-heading">Technologies</div>
            <div className="code-example-technologies-text">
                {example.tags.map((tag, idx) => (
                    <div className="technology-tag" key={idx}>{tag}</div>
                ))}
            </div>
         </div>
     </div>
    );
  }
}
