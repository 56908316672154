import React from "react";
import CodeExample from "./code-example";
import "./code-examples.scss";

export class CodeExamples extends React.Component {
  render() {
    const codeExamples = [
      {
        name: "Osteka.io",
        imageURL: "osteka.png",
        link: "https://osteka.io",
        description:
          "An Agile/Scrum story-pointing platform I designed and built; used by teams at Amazon, IBM, and Google.",
        tags: ["Ember.js", "Sass", "JavaScript", "Firebase", "Stripe", "AWS"],
      },

      {
        name: "DiveViz",
        imageURL: "diveviz.png",
        link: "https://www.diveviz.com/pages/about-the-diveviz-app",
        description:
          "A popular NOAA iOS and Android app that I helped build helps divers track and retrieve data about dive sites.",
        tags: [
          "React Native",
          "Expo",
          "TypeScript",
          "Firebase",
          "Laravel",
          "GraphCMS",
          "PHP",
          "MySQL",
        ],
      },

      {
        name: "Task Rails",
        imageURL: "task-rails.png",
        link: "https://taskrails.com/",
        description:
          "A platform I designed and built to help the service industry better manage their workforce and client interactions.",
        tags: ["Ember.js", "Sass", "JavaScript", "Firebase", "AWS"],
      },

      {
        name: "Regal Reader",
        imageURL: "regal-reader.png",
        link: "https://regalreader.com/",
        description:
          "A tool I built to help me and others track their 'takeaways' from books.",
        tags: [
          "Ember.js",
          "Sass",
          "JavaScript",
          "Firebase",
          "Stripe",
          "AWS",
          "ISBNDB",
        ],
      },

      {
        name: "Animus.io",
        imageURL: "aniumus.png",
        link: "https://animus.netlify.app/",
        description:
          "A tool I designed and built for developers and attorneys to quickly create and reference 'nodes' - using only your keyboard.",
        tags: ["Ember.js", "Sass", "JavaScript", "Firebase", "AWS"],
      },

      {
        name: "Sitter Square",
        imageURL: "sitter-square.png",
        link: "https://sittersquare.com/",
        description:
          "A platform I designed and built to help parents find qualified and reputable babysitters around them.",
        tags: [
          "Ember.js",
          "Sass",
          "JavaScript",
          "Firebase",
          "Stripe",
          "Facebook Integration",
        ],
      },

      {
        name: "Closing Docket",
        imageURL: "closing-docket.png",
        link: "https://closingdocket.com/",
        description:
          "A tool I designed and built to help attorney finalize residential home closings faster and safer.",
        tags: ["Ember.js", "Sass", "JavaScript", "PHP", "MySQL"],
      },

      {
        name: "DotTrot",
        imageURL: "dottrot.png",
        link: "https://www.dottrot.com/",
        description:
          "An app I help design and build that helps groups travel easier.",
        tags: ["React Native", "Expo", "JavaScript", "GoLang"],
      },
    ];

    return (
      <div id="code-examples-wrapper">
        {" "}
        {codeExamples.map((example, idx) => (
          <CodeExample key={idx} example={example} />
        ))}
      </div>
    );
  }
}
