import React from "react";
import "./ui-ux.scss";
import { Header } from "../../components/header/header.jsx";
import { UIUXExamples } from "../../components/ui-ux-examples/ui-ux-examples.jsx";

export class UIUX extends React.Component {
  render() {
    return (
      <div className="ui-ux-wrapper">
        <Header description="UI + UX" />
        <UIUXExamples />
      </div>
    );
  }
}
