import React from "react";
import "./ui-ux-examples.scss";
import { UIUXExample } from "./ui-ux-example.jsx";
import { parallaxTiltEffect } from "./hover-effect.js";

export class UIUXExamples extends React.Component {
  componentDidMount() {
    const $ = (e) => document.querySelector(e);
    for (var i = 1; i <= 17; i++) {
      if (i === 4) {
        for (var j = 1; j <= 2; j++) {
          //   console.log(`.ui-ux-element-${i}-${j}`);
          new parallaxTiltEffect({
            element: $(`.ui-ux-element-${i}-${j}`),
            tiltEffect: "reverse",
          });
        }
      } else {
        // console.log(`element: .ui-ux-element-${i}`);
        new parallaxTiltEffect({
          element: $(`.ui-ux-element-${i}`),
          tiltEffect: "reverse",
          subtle: i === 13 ? true : false,
        });
      }
    }
  }
  render() {
    return (
      <div className="ui-ux-examples-wrapper">
        <div className="row-1 row">
          <div className="row-1-left">
            <div className="row-1-left-top">
              <UIUXExample idx="1" />
            </div>
            <div className="row-1-left-bottom">
              <UIUXExample idx="3" appendClass="left" />
              <UIUXExample hideOnMobile={true} idx="4-2" appendClass="middle" />
              <UIUXExample hideOnMobile={true} idx="4-1" appendClass="right" />
            </div>
          </div>
          <div className="row-1-right">
            <UIUXExample idx="2" />
          </div>
        </div>
        <div className="row-2 row">
          <div className="row-2-left">
            <UIUXExample idx="5" />
          </div>
          <div className="row-2-right">
            <UIUXExample idx="6" />
          </div>
        </div>
        <div className="row-3 row">
          <div className="row-3-left">
            <UIUXExample idx="7" />
          </div>
          <div className="row-3-right">
            <UIUXExample idx="8" />
          </div>
        </div>
        <div className="row-4 row">
          <UIUXExample idx="9" />
        </div>
        <div className="row-5 row">
          <div className="row-5-left">
            <UIUXExample idx="10" />
          </div>
          <div className="row-5-right">
            <UIUXExample idx="11" />
          </div>
        </div>
        <div className="row-6 row">
          <div className="row-6-left-column">
            <div className="row-margin-bottom">
              <UIUXExample idx="12" />
            </div>
            <div className="row-margin-bottom">
              <UIUXExample idx="14" />
            </div>
            <div className="row-margin-bottom">
              <UIUXExample idx="15" />
            </div>
            <UIUXExample idx="16" />
          </div>
          <div className="row-6-right-column">
            <div className="row-margin-bottom">
              <UIUXExample idx="13" />
            </div>
            <UIUXExample idx="17" />
          </div>
        </div>
      </div>
    );
  }
}
